// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-ebook-autodidatismo-para-iniciantes-js": () => import("./../../../src/pages/ebook-autodidatismo-para-iniciantes.js" /* webpackChunkName: "component---src-pages-ebook-autodidatismo-para-iniciantes-js" */),
  "component---src-pages-ebook-estudos-mais-produtivos-js": () => import("./../../../src/pages/ebook-estudos-mais-produtivos.js" /* webpackChunkName: "component---src-pages-ebook-estudos-mais-produtivos-js" */),
  "component---src-pages-ebook-mapa-meta-aprendizado-js": () => import("./../../../src/pages/ebook-mapa-meta-aprendizado.js" /* webpackChunkName: "component---src-pages-ebook-mapa-meta-aprendizado-js" */),
  "component---src-pages-ebook-organize-estude-melhor-js": () => import("./../../../src/pages/ebook-organize-estude-melhor.js" /* webpackChunkName: "component---src-pages-ebook-organize-estude-melhor-js" */),
  "component---src-pages-ebook-tecnica-feynman-js": () => import("./../../../src/pages/ebook-tecnica-feynman.js" /* webpackChunkName: "component---src-pages-ebook-tecnica-feynman-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

